import { isEmpty, sortBy } from 'lodash';
import { DateTime } from 'luxon';

import {
	GenerateServiceProtocolData,
	GetServiceProtocolInitialDatesDto,
	PerformedOperationRequest,
	SaveServiceProtocolCommand,
	SaveServiceProtocolDraftCommand,
	ServiceActivityEventsInfoDto,
	ServiceProtocolStatus,
} from 'api';
import { ServiceProtocolActivitiesQueryParams } from 'api/queries/ServiceProtocolQueries';
import { ChassisId } from 'domain/driveline';
import { ParseKeys, TOptions } from 'i18next';
import {
	fromIsoToDate,
	fromIsoToLuxonDate,
	fromJsDateToLuxonDate,
} from 'library/formatters';
import { FileUploadState } from 'library/redux/fileUpload';

import {
	ActivitiesType,
	ActivityType,
	ServiceActivityType,
	ServiceInfoStepFormType,
	ServiceInfoStepStateType,
} from '../Types';
import { GenerateServiceProtocolSlice } from './Types';

export const mapFromFormToState = ({
	lastServiceDate,
	productInOperationDate,
	serviceDate,
	...formRest
}: ServiceInfoStepFormType): ServiceInfoStepStateType => ({
	...formRest,
	lastServiceDate: lastServiceDate?.toJSON() || '',
	productInOperationDate: productInOperationDate?.toJSON() || '',
	serviceDate: serviceDate?.toJSON() || '',
});

export const mapFromStateToForm = (
	state: ServiceInfoStepStateType
): ServiceInfoStepFormType =>
	isEmpty(state)
		? ({} as ServiceInfoStepFormType)
		: {
				...state,
				lastServiceDate: state.lastServiceDate
					? fromIsoToLuxonDate(state.lastServiceDate)
					: null,
				productInOperationDate: fromIsoToLuxonDate(
					state.productInOperationDate
				),
				serviceDate: fromIsoToLuxonDate(state.serviceDate),
			};

export const mapFromInitialDatesToForm = (
	data: GetServiceProtocolInitialDatesDto,
	language: string | null,
	serviceDate: string | null
): ServiceInfoStepFormType => ({
	lastServiceOperatingHours: data.lastProtocolRunningHours,
	lastServiceDate: fromJsDateToLuxonDate(data.lastServiceDate),
	operatingHours: data.currentEngineRunningHours,
	productInOperationDate: fromJsDateToLuxonDate(data.productInOperation),
	serviceDate: serviceDate
		? fromIsoToLuxonDate(serviceDate)
		: DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
	language,
});

export const mapFromStateToQueryParams = (
	{
		language,
		lastServiceDate,
		lastServiceOperatingHours,
		operatingHours,
		productInOperationDate,
		serviceDate,
	}: ServiceInfoStepStateType,
	chassisId: ChassisId
): ServiceProtocolActivitiesQueryParams => ({
	...chassisId,
	currentEngineHours: operatingHours ?? 0,
	preferredLanguage: language ?? '',
	productInOperationDate: fromIsoToDate(productInOperationDate),
	serviceDate: fromIsoToDate(serviceDate),
	lastServiceDate: lastServiceDate ? fromIsoToDate(lastServiceDate) : null,
	lastServiceEngineHours: lastServiceOperatingHours,
});

export const mapGenerateServiceProtocolData = (
	serviceInfo: ServiceInfoStepStateType
): GenerateServiceProtocolData => ({
	preferredLanguage: serviceInfo.language ?? '',
	serviceDate: new Date(serviceInfo.serviceDate),
	productInOperationDate: new Date(serviceInfo.productInOperationDate),
	currentEngineHours: serviceInfo.operatingHours ?? 0,
	lastServiceEngineHours: serviceInfo.lastServiceOperatingHours ?? null,
	lastServiceDate: serviceInfo.lastServiceDate
		? new Date(serviceInfo.lastServiceDate)
		: null,
});

const mapServiceActivities = (
	data: ActivitiesType
): PerformedOperationRequest[] => {
	const serviceActivityKeys = Object.keys(data.activities);
	const performedOperations: SaveServiceProtocolDraftCommand['performedOperations'] =
		[];

	for (const activityId of serviceActivityKeys) {
		const serviceActivityId = parseInt(activityId);
		const activityServices = data.activities[serviceActivityId].services;
		const servicesKeys = Object.keys(
			data.activities[serviceActivityId].services
		);

		for (const servicesKey of servicesKeys) {
			const operationId = parseInt(servicesKey);

			const { comment, isPerformed, isProposal, photo } =
				activityServices[operationId];

			performedOperations.push({
				serviceActivityId,
				operationId,
				comment: comment ?? null,
				isPerformed: isPerformed ?? null,
				isProposal: isProposal ?? null,
				photo: photo ?? null,
			});
		}
	}

	return performedOperations;
};

export const mapFromStateToRequest = async (
	data: GenerateServiceProtocolSlice,
	fileState: FileUploadState
): Promise<SaveServiceProtocolCommand> => ({
	drivelineId: data.drivelineId,
	serviceProtocolId: data.serviceProtocolId,
	generateServiceProtocolData: mapGenerateServiceProtocolData(
		data.serviceInfo
	),
	location: data.location,
	comment: data.offlineActivities.additionalComment,
	customProtocolName: data.offlineActivities.serviceName,
	folderName: fileState.attachmentFolderName,
	offlineProtocolFileName:
		fileState.filesUploaded.find(({ isAdditional }) => !isAdditional)
			?.serverName || '',
	uploadedFiles: fileState.filesUploaded
		.filter(({ isAdditional }) => isAdditional)
		.map(({ serverName }) => serverName),
});

export const mapFromActivitiesTypeToDraftRequest = (
	drivelineId: number,
	activities: ActivitiesType
): SaveServiceProtocolDraftCommand => ({
	drivelineId,
	performedOperations: mapServiceActivities(activities),
	usedNumberOfHours: parseInt(activities.usedHours),
});

export const mapFromStateToDraftRequest = async (
	data: GenerateServiceProtocolSlice,
	fileState: FileUploadState
): Promise<SaveServiceProtocolDraftCommand> => ({
	drivelineId: data.drivelineId,
	id: data.serviceProtocolId,
	generateServiceProtocolData: mapGenerateServiceProtocolData(
		data.serviceInfo
	),
	performedOperations: mapServiceActivities(data.activities),
	usedNumberOfHours: parseInt(data.activities.usedHours),
	alreadyUploadedFilesToBeDeleted: [], // todo - add any deleted already uploaded files here
	location: Object.values(data.location).some((v) => !v)
		? null
		: data.location,
	comment: data.additionalRepairs.description,
	attachmentFolderName: fileState.attachmentFolderName,
	uploadedFiles: fileState.filesUploaded
		.filter(({ isAdditional }) => isAdditional)
		.map(({ serverName }) => serverName),
});

export const mapQueryResponseToType = (
	dto?: ServiceActivityEventsInfoDto
): ActivityType[] => {
	if (!dto) {
		return [];
	}

	return dto.serviceActivityEvents.map(
		({
			description,
			engineHours,
			estimatedHours,
			isPeriodic,
			months,
			operations,
			recommended,
			serviceActivityId,
			sortOrder,
		}) => ({
			estimation: estimatedHours,
			header: {
				description: description || '',
				hours: engineHours || 0,
				isPeriodic: isPeriodic || false,
				months,
			},
			services: sortBy(
				operations.map(
					({
						description,
						isEmissionRelated,
						lastPerformed,
						operationId,
						sortOrder,
						task: { type },
					}) => ({
						action: type,
						id: operationId,
						isEmissionRelated,
						lastServiceDate: lastPerformed,
						name: description,
						sortOrder,
					})
				) as ServiceActivityType[],
				({ sortOrder }) => sortOrder
			),
			id: serviceActivityId,
			isRecommended: recommended,
			sortOrder,
		})
	);
};
export const getServiceProtocolStatusToTranslationMapping = (
	status: ServiceProtocolStatus | null
): ParseKeys<['serviceProtocol'], TOptions, 'serviceProtocol'> => {
	switch (status) {
		case 'Initiated':
			return 'serviceProtocol:status-initiated';
		case 'Finalized':
			return 'serviceProtocol:status-finalized';
		default:
			return 'serviceProtocol:status-not-saved';
	}
};
