import { Button, ButtonOwnProps, CircularProgress, Link } from '@mui/material';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRight } from 'assets/volvoIcons';
import { Id } from 'domain/Id';
import { ServiceProtocolStatus } from 'domain/serviceProtocol';
import { getServiceProtocolDetailsLink } from 'library/components/link';
import { useBlockClick } from 'library/hooks';

interface ServiceProtocolDetailsLinkProps
	extends Id,
		Pick<ButtonOwnProps, 'color' | 'variant'> {
	Icon?: ReactNode;
	status: ServiceProtocolStatus;
	returnUrl?: string;
}
export const ServiceProtocolDetailsLink = memo(
	({
		color,
		Icon = <ArrowRight />,
		id,
		returnUrl,
		status,
		variant = 'text',
	}: ServiceProtocolDetailsLinkProps) => {
		const { t } = useTranslation(['commonButton']);
		const { disabled, handleClick } = useBlockClick();

		return (
			<Button
				color={color}
				component={Link}
				endIcon={
					!disabled ? (
						Icon
					) : (
						<CircularProgress size="1em" color="inherit" />
					)
				}
				href={getServiceProtocolDetailsLink(id, returnUrl)}
				disabled={disabled}
				onClick={handleClick}
				sx={{ mr: 2 }}
				variant={variant}>
				{status === 'Initiated'
					? t('commonButton:edit')
					: t('commonButton:view')}
			</Button>
		);
	}
);

ServiceProtocolDetailsLink.displayName = 'ServiceProtocolDetailsLink';
