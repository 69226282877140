import { productCenterApi } from 'api';
import { createWizardSubmitThunk } from 'library/hooks/Wizard/WizardSubmitHelpers';
import { createResetStateThunk } from 'library/redux/fileUpload/Thunks';

import {
	mapFromActivitiesTypeToDraftRequest,
	mapFromStateToDraftRequest,
	mapFromStateToRequest,
	mapGenerateServiceProtocolData,
} from './Mappers';
import {
	selectActivities,
	selectAll,
	selectDrivelineId,
	selectServiceInfo,
} from './Selectors';

export const resetState = createResetStateThunk(
	'generateServiceProtocolFiles/resetState',
	(s) => s.generateServiceProtocolFiles
);

export const submitServiceProtocol = createWizardSubmitThunk(
	'generateServiceProtocol/submitServiceProtocol',
	async (root) => {
		const { generateServiceProtocolFiles } = root;

		const generateServiceProtocol = selectAll(root);

		const request = await mapFromStateToRequest(
			generateServiceProtocol,
			generateServiceProtocolFiles
		);

		return await productCenterApi.saveServiceProtocol(request);
	}
);

export const submitServiceProtocolDraft = createWizardSubmitThunk(
	'generateServiceProtocol/submitServiceProtocolDraft',
	async (root) => {
		const { generateServiceProtocolFiles } = root;

		const generateServiceProtocol = selectAll(root);

		const request = await mapFromStateToDraftRequest(
			generateServiceProtocol,
			generateServiceProtocolFiles
		);

		return await productCenterApi.saveServiceProtocolDraft(request);
	}
);

export const submitSecondStepServiceProtocolDraft = createWizardSubmitThunk(
	'generateServiceProtocolFiles/submitSecondStepServiceProtocolDraft',
	async (root) => {
		const { generateServiceProtocolFiles } = root;
		const serviceInfo = selectServiceInfo(root);
		const activities = selectActivities(root);
		const drivelineId = selectDrivelineId(root);

		const request = mapFromActivitiesTypeToDraftRequest(
			drivelineId,
			activities
		);
		request.generateServiceProtocolData =
			mapGenerateServiceProtocolData(serviceInfo);
		request.attachmentFolderName =
			generateServiceProtocolFiles.attachmentFolderName;

		return await productCenterApi.saveServiceProtocolDraft(request);
	}
);
