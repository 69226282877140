import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CurrentLocationBaseDto, ServiceProtocolStatus } from 'api';

import {
	ActivitiesType,
	AdditionalRepairsType,
	OfflineActivitiesType,
	ServiceInfoStepStateType,
} from '../Types';
import { GenerateServiceProtocolSlice } from './Types';

export const initialState: GenerateServiceProtocolSlice = {
	serviceProtocolId: null,
	drivelineId: 0,
	activeStep: 0,
	activities: { activities: [], usedHours: '0' },
	additionalRepairs: {} as AdditionalRepairsType,
	isOnline: true,
	location: {} as CurrentLocationBaseDto,
	offlineActivities: {
		additionalComment: '',
		serviceName: '',
	},
	serviceInfo: {} as ServiceInfoStepStateType,
	status: null,
};

export const generateServiceProtocolSlice = createSlice({
	name: 'generateServiceProtocol',
	initialState,
	reducers: {
		nextStep: (state) => {
			state.activeStep += 1;
		},
		previousStep: (state) => {
			if (state.activeStep === 0) {
				return;
			}

			state.activeStep -= 1;
		},
		reset: () => initialState,
		setAdditionalRepairs: (
			state,
			{ payload }: PayloadAction<AdditionalRepairsType>
		) => {
			state.additionalRepairs = payload;
		},
		setActivities: (state, { payload }: PayloadAction<ActivitiesType>) => {
			state.activities = payload;
		},
		setDrivelineId: (state, { payload }: PayloadAction<number>) => {
			state.drivelineId = payload;
		},
		serviceProtocolPageLoaded: (
			state,
			{
				payload,
			}: PayloadAction<{
				drivelineId: number;
				serviceProtocolId: number | null | undefined;
			}>
		) => {
			state.drivelineId = payload.drivelineId;

			if (payload.serviceProtocolId != null) {
				state.serviceProtocolId = payload.serviceProtocolId;
			}
		},
		setIsOnline: (state, { payload }: PayloadAction<boolean>) => {
			state.isOnline = payload;
		},
		setLocation: (
			state,
			{ payload }: PayloadAction<CurrentLocationBaseDto>
		) => {
			state.location = payload;
		},
		setOfflineActivities: (
			state,
			{ payload }: PayloadAction<OfflineActivitiesType>
		) => {
			state.offlineActivities = payload;
		},
		setServiceInfo: (
			state,
			{ payload }: PayloadAction<ServiceInfoStepStateType>
		) => {
			state.serviceInfo = payload;
		},
		setStatus: (
			state,
			{ payload }: PayloadAction<ServiceProtocolStatus | null>
		) => {
			state.status = payload;
		},
		setStep: (state, { payload }: PayloadAction<number>) => {
			state.activeStep = payload;
		},
	},
});

export const {
	actions: {
		nextStep,
		previousStep,
		reset,
		setActivities,
		setAdditionalRepairs,
		setDrivelineId,
		serviceProtocolPageLoaded,
		setIsOnline,
		setLocation,
		setOfflineActivities,
		setServiceInfo,
		setStatus,
		setStep,
	},
} = generateServiceProtocolSlice;

export default generateServiceProtocolSlice.reducer;
