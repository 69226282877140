import {
	createFileUploadSlice,
	initialFileState,
} from 'library/redux/fileUpload';

import { resetState } from './Thunk';

const generateServiceProtocolFilesSlice = createFileUploadSlice({
	name: 'generateServiceProtocolFiles',
	initialState: initialFileState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(resetState.fulfilled, () => initialFileState);
	},
});

export const { fileUploaded, fileDeleted, attachmentFolderCreated } =
	generateServiceProtocolFilesSlice.actions;

export default generateServiceProtocolFilesSlice.reducer;
